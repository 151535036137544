.calendar-box {
    background-color: #fff;
    padding: 0 10px;
}

.buttons-container {
  cursor: pointer;
}

.week {
  overflow: hidden;
}

.week.highlight {
  background: #efefef;
}   

/* weekend */
.week .day:first-child, .week .day:last-child {
  /*color: orange;*/
}

/* sunday */
.week .day:first-child {
  /*color: red;*/
}

.day {
  display: inline-block;
  float: left;
  width: 14.28%;
  text-align: center;
  cursor: pointer;
  box-sizing: border-box;
}

.day.disabled span {
  color: #AAA;
}

/* vertical highlight */
.week .day.highlight span {
  /*color: blue;*/
}

.day.selected span {
  /*background: orange;*/
}

.day.today span {
  font-weight: bold;
}

.months-container.hidden, .weeks-container.hidden {
  display: none;
}

.months-wrapper {
  overflow: hidden;
}

.months-wrapper .month {
  display: inline-block;
  float: left;
  width: 25%;
  text-align: center;
  cursor: pointer;
}
.months-wrapper .month.one-third {
  width: 33.33%;
}
