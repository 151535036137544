.buttons-container {
  display: -webkit-box;
  display: -moz-box;
  display: -ms-flexbox;
  display: -webkit-flex;
  display: flex;
  align-items: center;
  padding-bottom: 10px;
  border-bottom: 1px solid #eee;
}

.buttons-container .label-container {
  display: inline-block;
  -webkit-box-flex: 1;
  -moz-box-flex: 1;
  -webkit-flex: 1; 
  -ms-flex: 1;
  flex: 1;
  text-align: center;
  text-transform: uppercase;
  font-weight: bold;
}

.year-dropdown {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  border: none;
  font-size: inherit;
  font-weight: inherit;
  font-family: inherit;
  padding: 5px 20px;
}

.prev-button,
.next-button {
  background: transparent;
  border: none;
  padding: 10px;
}

.week {
  margin: 0 0;
  display: flex;
}

.week.highlight {
  border-radius: 5px;
}

.weeks-wrapper.header {
  border-bottom: 1px solid #eee;
}
.weeks-wrapper.header .week {
    margin: 10px 0 10px 0;
}

.week .day.header {
  font-weight: 400;
  font-size: 14px;
}

.day span {
  display: inline-block;
  width: 40px;
  height: 40px;
  line-height: 40px;
  border-radius: 50%;
  vertical-align: middle;
}

.day.today span {
  position: relative;
  display: inline-block;
  font-size: 110%;
}

/* weekend */
.week:not(.start-on-monday) .day:first-child,
.week:not(.start-on-monday) .day:last-child {
  color: orange;
}

/* sunday */
.week:not(.start-on-monday) .day:first-child {
  color: red;
}

/* start on monday - weekend */
.week.start-on-monday .day:nth-child(6),
.week.start-on-monday .day:last-child {
  color: orange;
}

/* start on monday - sunday */
.week.start-on-monday .day:last-child {
  color: red;
}

.day.today span::after {
  content: "";
  position: absolute;
  bottom: 7px;
  left: 50%;
  transform: translateX(-50%);
  border-bottom: 2px solid orange;
  width: 10px;
  height: 1px;
}

.day.sunday span {
  color: #ff8a80;
}

.week .day.highlight span {
  color: #2196f3;
}

.week .day.selected span {
  background: #1565c0;
  color: white;
}

.week .day[disabled="disabled"] span {
  color: #aaa;
  cursor: not-allowed;
}

.months-wrapper .month span {
  display: inline-block;
  padding: 10px;
  text-transform: capitalize;
  margin-bottom: 10px;
}

.special-buttons {
  text-align: center;
  border-top: 1px solid #eee;
}

.today-button {
  margin: 0 auto;
  background: transparent;
  border: none;
  padding: 5px;
}